<template>
  <div class="addAdministrator">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>编辑游戏</el-breadcrumb-item>
    </el-breadcrumb>
    <el-form
      class="demo-form-inline"
      :model="formList"
      :rules="rules"
      ref="ruleForm"
    >
      <!-- <el-form-item label="游戏id" prop="userName">
        <el-input
          v-model.trim="gameInfo.gameId"
          placeholder="游戏id"
        ></el-input>
      </el-form-item> -->
      <el-form-item label="" prop="userName">
          <span style="margin-right:35px;">游戏标题</span>
        <el-input
          value="asldkj"
          v-model.trim="detailData.title"
          placeholder="游戏标题"
        ></el-input>
      </el-form-item>
      <!-- <el-form-item label="活动开始时间" prop="userName">
        <el-input
          v-model.trim="gameInfo.beginTime"
          placeholder="活动开始时间"
        ></el-input>
      </el-form-item>
      <el-form-item label="活动截止时间" prop="userName">
        <el-input
          v-model.trim="gameInfo.endTime"
          placeholder="活动截止时间"
        ></el-input>
      </el-form-item> -->
       <span class="demonstration" style="margin-right:33px;">开始时间</span>
    <el-date-picker
      v-model="detailData.beginTime"
      format="yyyy-MM-dd HH:mm:ss"
      value-format="yyyy-MM-dd HH:mm:ss"
      type="datetime"
      placeholder="选择日期时间">
    </el-date-picker>
    <br/>
    <br/>
          <el-form-item label="" prop="userTrueName">
    <span class="demonstration"  style="margin-right:35px;">结束时间</span>
    <el-date-picker
      v-model="detailData.endTime"
      type="datetime"
          format="yyyy-MM-dd HH:mm:ss"
      value-format="yyyy-MM-dd HH:mm:ss"
      placeholder="选择日期时间">
    </el-date-picker>
      </el-form-item>
      <el-form-item label="" prop="userTrueName" style="margin-right:12px;">
        <span style="margin-right:27px;">凡科appId</span>
        <el-input
          v-model.trim="detailData.appId"
                format="yyyy-MM-dd HH:mm:ss"
      value-format="yyyy-MM-dd HH:mm:ss"
          placeholder="凡科appId"
        ></el-input>
      </el-form-item>
      <el-form-item label="" prop="fkName" >
          <span style="margin-right:12px;">凡科页面路径</span>
        <el-input
          v-model.trim="detailData.pageUrl"
          placeholder="凡科页面路径"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="confirmAddGame">保存</el-button>
        <el-button type="primary" @click="$router.go(-1)">返回</el-button>
      </el-form-item>
    </el-form>
    
  </div>
</template>
<script>
import { gameDetail,addGame } from "../../../api/gameTools";

import sha1 from "sha1";
export default {
  name: "AddAdministrator",
  data() {
    var validateUName = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入游戏名"));
      } 
    };
    var validateTrueUName = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入凡科appId"));
      } else if (value.length < 2 || value.length > 6) {
        callback(new Error("请输入2到6位的字符"));
      } else {
        callback();
      }
    };
    var validateTrueUName2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入凡科appId"));
      } else if (value.length < 2 || value.length > 6) {
        callback(new Error("请输入2到6位的字符"));
      } else {
        callback();
      }
    };
    var validatePass = (rule, value, callback) => {
      var psw = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,20}$/;
      if (value === "") {
        callback(new Error("请输入密码"));
      } else if (!psw.test(value)) {
        callback(new Error("请输入6 到 20 位的数字、字母组合"));
      } else {
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      console.log(value, "value");
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.formList.userPassword) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    var validateTelNumber = (rule, value, callback) => {
      var tel = /^1[3|4|5|7|8][0-9]{9}$/;
      if (value === "") {
        callback(new Error("请输入电话号码"));
      } else if (!tel.test(value)) {
        callback(new Error("请输入11位数的电话号码"));
      } else {
        callback();
      }
    };
    return {
        //时间选择器
         pickerOptions: {
          shortcuts: [{
            text: '今天',
            onClick(picker) {
              picker.$emit('pick', new Date());
            }
          }, {
            text: '昨天',
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit('pick', date);
            }
          }, {
            text: '一周前',
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', date);
            }
          }],
        },
        value1: '',
        value2: '',
        value3: '',
      checkPass: "",
      sexValue: "",
      formList: {
        userName: "",
        userTrueName: "",
        userPassword: "",
        userHeadImg: "",
        userPhone: "",
        roleId: null,
        userSex: null,
      },
      roleList: [], //角色列表
      value: "", //角色主键
      src:
        "https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg",
      rules: {
        // userPassword: [{ validator: validatePass, trigger: "blur" }],
        // checkPass: [{ validator: validatePass2, trigger: "blur" }],
        // userPhone: [{ validator: validateTelNumber, trigger: "blur" }],
        // userName: [{ validator: validateUName, trigger: "blur" }],
        // userTrueName: [{ validator: validateTrueUName, trigger: "blur" }],
        // fkName: [{ validator: validateTrueUName2, trigger: "blur" }],
      },
      gameInfo:{
          gameId:0,
          title:'',
          beginTime:'',
          endTime:'',
          appId:'',
          pageUrl:'',
          gameId:'',
      },
     detailData:{
         title:'',
          beginTime:'',
          endTime:'',
          appId:'',
          pageUrl:'',
          gameId:'',
     },
    };
  },
  watch: {
    sexValue: function () {
      this.formList.userSex = Number(this.sexValue);
    },
  },
  created() {
    // this.getRoleList();
    this.gameId = this.$route.query.gameId
    this.getGameDetail()
  },
  methods: {
            //游戏详情
      async getGameDetail(){
          this.dialogVisible=true
          const { data } = await gameDetail({
              gameId:this.gameId
          })
         console.log('data',data)
         this.detailData = data.data[0]
         console.log(this.detailData,'lkasdjlkasjd')
         console.log()
      },
      //添加游戏
      async confirmAddGame(){
          const {data} = await addGame(this.detailData)
          console.log(data)
          if(this.detailData.title.length !== ''){
              this.$message({
          message: '保存成功',
          type: 'success'
        });
          }
      },
  },
};
</script>
<style lang="less" scoped>
.addAdministrator {
  .el-breadcrumb {
    height: 35px;
    border-bottom: 1px solid rgb(192, 191, 191);
  }
  /deep/.el-form {
    margin-top: 50px;
    .el-form-item {

      .el-form-item__content {
        .el-form-item__error {
          margin-left: 75px;
        }
      }
    }
    .avatar {
      display: flex;
      align-items: center;
      .el-form-item__content {
        display: flex;
        align-items: center;
        .img {
          position: relative;
          margin: 0 20px;
          height: 100px;
          width: 100px;
          img {
            height: 100px;
            width: 100px;
            border: 1px solid #c0ccda;
          }
          div {
            position: absolute;
            top: 0;
            right: 5px;
            height: 10px;
            width: 10px;
            z-index: 100;
          }
        }
        .addImg {
          display: inline-block;
          width: 100px;
          height: 100px;
          border: 1px dashed #c0ccda;
          text-align: center;
          line-height: 100px;
          cursor: pointer;
          i {
            width: 50px;
            height: 50px;
            font-size: 20px;
            color: #c0ccda;
          }
        }
        span {
          margin-left: 30px;
          color: red;
        }
        .el-button {
          margin-right: 20px;
        }
      }
    }
    .el-form-item__label {
      width: 80px;
    }
    .el-input {
      width: 500px;
    }
  }
}
</style>